.template-dropzone {
    text-align: center;
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: #4D4D4D;

    border-radius: 10px;
    background-color: #EBF0F6;

    width: 50%;
    padding-top: 15%;
    padding-bottom: 15%;
}

.template-dropzone>* {
    margin-bottom: 1rem;
}


.template-dropzone-text {
    text-align: center;
    padding: 5%;
}