.template-select-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.template-browser {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}

.template-browser-button {
    margin-right: 2rem;
}