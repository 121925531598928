.error-toast {
    z-index: 2;
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0px);
}

.error-toast-log {
    text-align: left;
}

.error-toast-log a {
    color: black;
    text-decoration: underline;
}

.error-toast-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}