.main-container {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	text-align: center;
}

#title {
	font-size: 36px;
	letter-spacing: 0.2em;
	font-weight: 400;
	margin-top: 1em;
}

h3 {
	margin-top: 0.5em;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-size: 20px;
	font-weight: bold;
}

h4 {
	text-align: center;
	margin-top: 0.5em;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-size: 15px;
	font-weight: 600;
}