.display-GIS-map {
    width: 100%;
    height: 50vh;
    z-index: 1;
    /* position: relative; */
}

.popup-box {
    max-height: 250px;
    overflow: auto;
}